/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import PostCard from "../components/post-card"
import Pagination from "../components/post-list-pagination"

const PostListTemplate = ({ data, pageContext: { currentPage, numPages } }) => {
  const articles = data.allMdx.edges

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/${(currentPage - 1).toString()}`
  const nextPage = `/blog/${(currentPage + 1).toString()}`

  return (
    <div
      sx={{
        width: ["90%", "90%", "90%", "80%"],
        maxWidth: "calc(70ch + 350px + 32px)",
        position: "relative",
        margin: "0 auto",
        justifyContent: "center",
      }}
    >
      <ul
        sx={{
          width: ["100%", "100%", "100%", "min(calc(70ch + 350px), 100%)"],
          maxWidth: "960px",
          margin: "0 auto",
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "1fr",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
          ],
          gridGap: "32px",
          padding: "0",
        }}
      >
        {articles.map(article => {
          const path = article.node.fields.slug

          return (
            <PostCard
              key={
                article.node.frontmatter.slug
                  ? article.node.frontmatter.slug
                  : article.node.fields.slug
              }
              article={article}
              path={path}
            />
          )
        })}
      </ul>

      <Pagination
        prevPage={prevPage}
        nextPage={nextPage}
        numPages={numPages}
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
      />
    </div>
  )
}

PostListTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PostListTemplate

// to create pagination -- use in a graphql query in a postListTemplate file
// https://www.gatsbyjs.com/docs/adding-pagination/
export const listQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "post-list-template" }
          draft: { ne: true }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            section
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
